import React from 'react';
import { Router, Location } from '@reach/router';
import Loadable from 'react-loadable';
import Loading from 'components/loading.js';

import PrivateRoute from 'routes/PrivateRoute';

const LoadableLayout = Loadable({
  loader: () => import('layouts/Admin'),
  loading: Loading,
});
const LoadableAppSettings = Loadable({
  loader: () => import('views/dashboards/AppSetting'),
  loading: Loading,
});

const AccountClient = () => (
  <Location>
    {({ location }) => (
      <LoadableLayout>
        <Router basepath="/account" location={location}>
          <PrivateRoute component={LoadableAppSettings} path="/settings" />
        </Router>
      </LoadableLayout>
    )}
  </Location>
);

export default AccountClient;
